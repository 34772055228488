import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core';
import ContentLiftModule from '../../../../module/ContentLiftModule';
import RichText from '../../../../shared/RichText';

const styles = () => ({
  root: {},
});

function CampaignContentLifts({ classes, className, pageData }) {
  const contentLifts = get(pageData, 'contentLifts');

  if (isEmpty(contentLifts)) {
    return null;
  }
  return (
    <div className={clsx(classes.root, className)}>
      {map(contentLifts, (liftData, index) => {
        const id = get(liftData, 'id');
        return <ContentLiftModule data={liftData} pageData={pageData} key={id} richText={RichText} index={index} />;
      })}
    </div>
  );
}

CampaignContentLifts.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CampaignContentLifts.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(CampaignContentLifts);
