import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../../constants';
import { siteId } from '../../../../sites';

import FolkofolkCampaignOrderForm from './folkofolk';

function CampaignOrderForm(props) {
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkCampaignOrderForm {...props} />;
  }

  return null;
}

export default CampaignOrderForm;
