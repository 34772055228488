/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Stepper, Step, useTheme, withStyles, useMediaQuery, lighten } from '@material-ui/core';

import ArrowBack from '@material-ui/icons/ArrowBackRounded';
import ArrowForward from '@material-ui/icons/ArrowForwardRounded';
import { isEmpty, size, filter, isEqual } from 'lodash';
import Button from '../../../shared/Button';
import Visible from '../../../shared/Visible';

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginTop: theme.spacing(0),
    marginBottom: '0px',
  },

  carouselWrapper: {
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 0),
    },
  },

  carouselContentWrapper: {
    overflow: 'visible',
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },

  carouselContent: {
    display: 'flex',
    transition: 'all 350ms linear',
    gap: ({ gap }) => {
      return `${gap}px`;
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > *': {
      width: '100%',
      flexShrink: '0',
      flexGrow: '1',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: ({ elementsPerRowDesktop, children }) => {
        if (size(children) <= elementsPerRowDesktop) {
          return 'center';
        }
        return 'flex-start';
      },
    },
  },

  controlSection: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(3),
  },
  controlDots: {
    backgroundColor: 'transparent',
    padding: theme.spacing(0, 1),
    gap: '4px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  controlDot: {
    backgroundColor: theme.palette.common.gray,
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  controlDotActive: {
    height: '16px',
    width: '16px',
    border: `solid 4px ${lighten(theme.overrides.MuiButton.containedPrimary.backgroundColor, 0.7)}`,
    backgroundColor: theme.overrides.MuiButton.containedPrimary.backgroundColor,
  },
  arrowIcon: {
    zIndex: '2',
    width: '48px',
    height: '48px',
    borderRadius: '9px',

    '&:$disabled': {
      color: 'white',
      border: 'transparent',
      backgroundColor: '#a0968d',
    },
    '&:hover': {
      '@media (hover: none)': {
        color: theme.overrides.MuiButton.containedPrimary.backgroundColor,
        border: `solid 2px ${theme.overrides.MuiButton.containedPrimary.backgroundColor}`,
        backgroundColor: 'transparent',
      },
    },
    '@media print': {
      display: 'none',
    },
  },
  icon: {},

  leftArrow: {
    [theme.breakpoints.up('sm')]: {},
  },

  rightArrow: {
    [theme.breakpoints.up('sm')]: {},
  },
});

function CampaignSlider({
  classes,
  className,
  children,
  elementsPerRowDesktop,
  elementsPerRowTablet,
  elementsPerRowMobile,
  gap,
}) {
  children = filter(children, child => {
    return !isEmpty(child);
  });
  const length = size(children);
  const [show, setShow] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchPosition, setTouchPosition] = useState(null);
  const [steps, setSteps] = useState(1);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 840));

  const stepsArray = new Array(steps).fill(undefined);
  useEffect(() => {
    if (isDesktop) {
      setShow(elementsPerRowDesktop);
    }
    if (isTablet) {
      setShow(elementsPerRowTablet);
    }
    if (!isDesktop && !isTablet) {
      setShow(elementsPerRowMobile);
    }
    if (length <= show) {
      setSteps(1);
    } else {
      setSteps(Math.ceil(length / show));
    }
  }, [
    isDesktop,
    isTablet,
    children,
    length,
    show,
    elementsPerRowDesktop,
    gap,
    elementsPerRowTablet,
    elementsPerRowMobile,
  ]);

  if (isEmpty(children)) {
    return null;
  }
  const handleNext = () => {
    if ((currentIndex + 1) * show < length) {
      setCurrentIndex(prevState => prevState + 1);
    }
  };
  const handelPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1);
    }
  };

  const handleStep = step => () => {
    setCurrentIndex(step);
  };

  const handleTouchStart = e => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = e => {
    const touchDown = touchPosition;
    if (touchDown === null) {
      return;
    }
    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      handleNext();
    }
    if (diff < -5) {
      handelPrev();
    }
    setTouchPosition(null);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.carouselWrapper}>
        <div className={classes.carouselContentWrapper} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
          <div
            className={classes.carouselContent}
            style={{
              transform: `translateX(-${currentIndex * 100}%) translateX(-${currentIndex * gap}px)`,
            }}>
            {children}
          </div>
        </div>
      </div>
      <Visible hidden={length - show <= 0}>
        <div className={classes.controlSection}>
          <Button
            onClick={handelPrev}
            className={clsx(classes.arrowIcon, classes.leftArrow)}
            disabled={currentIndex === 0}>
            <ArrowBack fontSize="small" />
          </Button>

          <Stepper activeStep={currentIndex} className={classes.controlDots} connector={null}>
            {stepsArray.map((label, index) => (
              <Step
                key={`key_${index}`}
                onClick={handleStep(index)}
                className={clsx(classes.controlDot, isEqual(currentIndex, index) ? classes.controlDotActive : null)}
              />
            ))}
          </Stepper>

          <Button
            onClick={handleNext}
            className={clsx(classes.arrowIcon, classes.rightArrow)}
            disabled={(currentIndex + 1) * show >= length || length - show <= 0}>
            <ArrowForward fontSize="small" />
          </Button>
        </div>
      </Visible>
    </div>
  );
}

CampaignSlider.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  elementsPerRowDesktop: PropTypes.number,
  elementsPerRowTablet: PropTypes.number,
  elementsPerRowMobile: PropTypes.number,
  gap: PropTypes.number,
};

CampaignSlider.defaultProps = {
  classes: {},
  className: null,
  children: null,
  elementsPerRowDesktop: 4,
  elementsPerRowTablet: 3,
  elementsPerRowMobile: 2,
  gap: 24,
};

export default withStyles(styles)(CampaignSlider);
