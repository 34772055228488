/* eslint-disable jsx-a11y/media-has-caption */
import React, { useMemo } from 'react';
import clsx from 'clsx';

import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Container, withStyles } from '@material-ui/core';

import { isEmpty } from 'lodash';
import { breakPoints } from '../../../../constants';
import CloudinaryImage from '../../../shared/CloudinaryImage';

const isSectionEven = index => {
  return index % 2 === 0;
};

const getColor = (customThemeStyle, defaultColor) => {
  return isEmpty(customThemeStyle) ? defaultColor : null;
};

const styles = theme => ({
  root: {
    backgroundColor: ({ pageData, index }) => {
      const appearance = get(pageData, 'appearance');

      const darkColor = get(theme, 'palette.pageTemplates.campaignPage.colors.darkColor', '#333333');
      const lightColor = get(theme, 'palette.pageTemplates.campaignPage.colors.lightColor', '#fff');

      if (appearance === 'Light') {
        return isSectionEven(index) ? darkColor : lightColor;
      }
      return isSectionEven(index) ? lightColor : darkColor;
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  image: {
    position: 'relative',
    minHeight: '240px',
    maxWidth: '100%',
    flexBasis: '100%',
    [theme.breakpoints.up('md')]: {
      minHeight: '450px',
      maxWidth: ({ data }) => {
        const content = get(data, 'content');
        if (isEmpty(content)) {
          return '100%';
        }
        return '50%';
      },
      flexBasis: ({ data }) => {
        const content = get(data, 'content');
        if (isEmpty(content)) {
          return '100%';
        }
        return '50%';
      },
      order: ({ index }) => {
        return isSectionEven(index) ? 0 : 1;
      },
    },
  },
  cloudinaryImage: {
    zIndex: 1,
    position: 'absolute',
    height: '100%',
  },

  fontColor: {
    color: ({ pageData, data, index }) => {
      const appearance = get(pageData, 'appearance');
      const image = get(data, 'image');

      const customThemeStyle = get(pageData, 'customThemeStyle', null);

      const darkColor = get(theme, 'palette.pageTemplates.campaignPage.colors.darkColor', '#333333');
      const lightColor = get(theme, 'palette.pageTemplates.campaignPage.colors.lightColor', '#fff');

      if (!isEmpty(image)) {
        if (appearance === 'Light') {
          return isSectionEven(index) ? getColor(customThemeStyle, darkColor) : lightColor;
        }
        return isSectionEven(index) ? getColor(customThemeStyle, lightColor) : darkColor;
      }

      if (appearance === 'Light') {
        return isSectionEven(index) ? lightColor : getColor(customThemeStyle, darkColor);
      }
      return isSectionEven(index) ? darkColor : getColor(customThemeStyle, lightColor);
    },
  },
  contentWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '100%',
    flexBasis: '100%',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 3),

      maxWidth: ({ data }) => {
        const image = get(data, 'image');
        if (isEmpty(image)) {
          return '100%';
        }
        return '50%';
      },
      flexBasis: ({ data }) => {
        const image = get(data, 'image');
        if (isEmpty(image)) {
          return '100%';
        }
        return '50%';
      },
    },
  },
  content: {
    position: 'relative',
    zIndex: '10',
    padding: theme.spacing(2),

    marginTop: ({ data }) => {
      const image = get(data, 'image');
      return isEmpty(image) ? 0 : theme.spacing(-6);
    },

    backgroundColor: ({ data, pageData, index }) => {
      const appearance = get(pageData, 'appearance');
      const image = get(data, 'image');

      const darkColor = get(theme, 'palette.pageTemplates.campaignPage.colors.darkColor', '#333333');
      const lightColor = get(theme, 'palette.pageTemplates.campaignPage.colors.lightColor', '#fff');

      if (isEmpty(image)) {
        return 'transparent';
      }

      if (appearance === 'Light') {
        return isSectionEven(index) ? lightColor : darkColor;
      }
      return isSectionEven(index) ? darkColor : lightColor;
    },

    [theme.breakpoints.up('md')]: {
      marginTop: ({ data }) => {
        const image = get(data, 'image');
        return isEmpty(image) ? 0 : 0;
      },
      padding: ({ data }) => {
        const image = get(data, 'image');
        return isEmpty(image) ? 0 : theme.spacing(6);
      },
      marginRight: ({ data, index }) => {
        const image = get(data, 'image');
        if (isEmpty(image)) {
          return 0;
        }
        return isSectionEven(index) ? 0 : theme.spacing(-6);
      },
      marginLeft: ({ data, index }) => {
        const image = get(data, 'image');
        if (isEmpty(image)) {
          return 0;
        }
        return isSectionEven(index) ? theme.spacing(-6) : 0;
      },
    },
  },
});

// eslint-disable-next-line no-unused-vars
function ContentLiftModule({ classes, className, data, pageData, index, richText: RichText }) {
  const image = get(data, 'image');
  const imgAltText = get(data, 'image.imageAltText');
  const imgTitleText = get(data, 'image.imageTitleText');
  const imgPublicId = get(data, 'image.cloudinaryImage.0.public_id');

  const content = get(data, 'content');

  const transformation = useMemo(() => {
    if (isEmpty(content)) {
      return 'ColumnImage-LG-SM';
    }
    return 'ColumnImage-MD-SM';
  }, [content]);

  return (
    <div className={clsx(classes.root, className)}>
      <Container disableGutters className={classes.container}>
        {!isEmpty(image) && (
          <div className={classes.image}>
            <CloudinaryImage
              className={classes.cloudinaryImage}
              publicId={imgPublicId}
              transformation={transformation}
              alt={imgAltText}
              title={imgTitleText}
              width={breakPoints.lg}
              height="480"
            />
          </div>
        )}
        {!isEmpty(content) && (
          <div className={classes.contentWrapper}>
            {!isEmpty(content) && (
              <RichText
                textData={content}
                className={classes.content}
                classes={{
                  root: classes.fontColor,
                  paragraph: classes.fontColor,
                  heading1: classes.fontColor,
                  heading2: classes.fontColor,
                  heading3: classes.fontColor,
                  heading4: classes.fontColor,
                  heading5: classes.fontColor,
                  heading6: classes.fontColor,
                  hyperlink: classes.linkFontColor,
                  quote: classes.fontColor,
                  listItem: classes.fontColor,
                  olList: classes.fontColor,
                  bold: classes.fontColor,
                  italic: classes.fontColor,
                  underline: classes.fontColor,
                }}
              />
            )}
          </div>
        )}
      </Container>
    </div>
  );
}

ContentLiftModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  richText: PropTypes.any,
  index: PropTypes.number,
};

ContentLiftModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  pageData: null,
  richText: null,
  index: null,
};

export default withStyles(styles)(ContentLiftModule);
