import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Typography, withStyles, lighten } from '@material-ui/core';
import Button from '../../../../shared/Button';

const styles = theme => ({
  root: {
    position: 'fixed',
    zIndex: 100,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: theme.spacing(1, 3),
    backgroundColor: ({ pageData }) => {
      const appearance = get(pageData, 'appearance');
      if (appearance === 'Light') {
        return '#ffffff';
      }
      return '#373737';
    },
    boxShadow: '0 0 60px 0 rgba(0, 0, 0, 0.1)',
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
  },
  startIcon: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: ({ pageData }) => {
      const primaryColor = get(pageData, 'mainTheme.themeStyles.primaryBrandColor');

      return isEmpty(primaryColor) ? null : lighten(primaryColor, 0.6);
    },
    marginRight: theme.spacing(1),
    '&>svg': {
      color: ({ pageData }) => {
        const primaryColor = get(pageData, 'mainTheme.themeStyles.primaryBrandColor');

        return isEmpty(primaryColor) ? null : primaryColor;
      },
    },
  },
  button: {
    borderRadius: '9px',
    height: '45px',
    textWrap: 'nowrap',
  },

  typography: {
    color: ({ pageData }) => {
      const appearance = get(pageData, 'appearance');
      const customThemeStyle = get(pageData, 'customThemeStyle', null);
      const lightColor = get(theme, 'palette.pageTemplates.campaignPage.colors.lightColor', '#fff');

      if (appearance !== 'Light' && isEmpty(customThemeStyle)) {
        return lightColor;
      }
      return null;
    },
  },
});

function CampaignHeaderElement({ classes, className, pageData }) {
  const { t } = useTranslation();
  const monopolyUrl = get(pageData, 'orderFormProduct.relatedProduct.attributes.monopoly_url.value', null);
  const newsletter = get(pageData, 'newsletter');
  if (isEmpty(newsletter)) {
    return null;
  }
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.text}>
        <span className={classes.startIcon}>
          <FiberManualRecordIcon fontSize="small" />
        </span>
        {isEmpty(monopolyUrl) && (
          <Typography className={classes.typography}>
            {' '}
            {t('CampaignPage.OrderForm.Subscribe.HeaderElement.Text')}
          </Typography>
        )}
        {!isEmpty(monopolyUrl) && (
          <Typography className={classes.typography}>
            {' '}
            {t('CampaignPage.OrderForm.Order.HeaderElement.Text')}
          </Typography>
        )}
      </div>
      {isEmpty(monopolyUrl) && (
        <Button to="#order_form" className={classes.button}>
          {t('CampaignPage.OrderForm.Subscribe.HeaderElement.ButtonLabel')}
        </Button>
      )}
      {!isEmpty(monopolyUrl) && (
        <Button to="#order_form" className={classes.button}>
          {t('CampaignPage.OrderForm.Order.HeaderElement.ButtonLabel')}
        </Button>
      )}
    </div>
  );
}

CampaignHeaderElement.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CampaignHeaderElement.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(CampaignHeaderElement);
