import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Formik, Form, Field } from 'formik';
import { withStyles, FormControl, lighten, Typography, Container } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';

import SendOutlinedIcon from '@material-ui/icons/SendOutlined';

import Button from '../../../../shared/Button';
import { submitCampaignPageForm, subscribeNewsletter } from '../../../../../utils/analyticUtils';

import { useCreateCampaignSubscription } from '../../../../hooks/useCreateCustobarSubscription';
import { getWindowPathName } from '../../../../../utils/windowUtils';
import { getUtmCampaignFromPath } from '../../../../../utils/custobarUtils';

const styles = theme => ({
  root: {
    color: '#333333',
    backgroundColor: 'transparent',
  },
  container: {
    padding: theme.spacing(8, 3),
  },
  form: {
    width: '100%',
  },
  formControl: {
    display: 'inline-flex',
    flexDirection: 'row',
    marginBottom: '0',
  },
  inputLabel: {
    paddingTop: theme.spacing(4),
    textTransform: 'uppercase',
  },
  formField: {
    width: '100%',
    marginBottom: '0',
    '& > div': {
      borderRadius: '9px 0 0 9px ',
      height: '48px',
      borderColor: ({ pageData }) => {
        const primaryColor = get(pageData, 'mainTheme.themeStyles.primaryBrandColor');
        return isEmpty(primaryColor) ? null : lighten(primaryColor, 0.3);
      },
      backgroundColor: ({ pageData }) => {
        const primaryColor = get(pageData, 'mainTheme.themeStyles.primaryBrandColor');
        return isEmpty(primaryColor) ? null : lighten(primaryColor, 0.98);
      },
    },
  },
  button: {
    borderRadius: '0 9px 9px 0',
    padding: '14px',
    border: '0',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: ({ pageData }) => {
        const primaryColor = get(pageData, 'mainTheme.themeStyles.primaryBrandColor');
        return isEmpty(primaryColor) ? null : primaryColor;
      },
      borderWidth: '1px',
      boxShadow: 'none',
    },
  },
  buttonIcon: {
    color: 'inherit',
    marginLeft: theme.spacing(0.5),
  },

  text: {
    color: '#333333',
  },
});

function CampaignNewsletter({ classes, className, pageData }) {
  const [isSuccess, setIsSuccess] = useState(false);

  const newsletter = get(pageData, 'newsletter');
  const mailingLists = get(newsletter, 'formType.custobarMailingLists');

  const { t } = useTranslation();

  const subscribePage = getWindowPathName();
  const utmCampainID = getUtmCampaignFromPath();

  const { mutateAsync: createCustobarSubscription } = useCreateCampaignSubscription();

  const handleFormSubmit = useCallback(
    async ({ emailAddress }, { resetForm }) => {
      try {
        const { data: response } = await createCustobarSubscription({
          emailAddress,
          mailingLists,
          subscribePage,
          utmCampainID,
        });

        if (response.message === 'Success') {
          setIsSuccess(true);
        }
        subscribeNewsletter();
        submitCampaignPageForm();

        resetForm();
      } catch (error) {
        console.error(error);
      }
    },
    [createCustobarSubscription, mailingLists, subscribePage, utmCampainID],
  );

  const initialValues = useMemo(
    () => ({
      emailAddress: '',
    }),
    [],
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      emailAddress: Yup.string()
        .email(t('CustobarSubscriptionForm.errors.emailAddress.invalid'))
        .required(t('CustobarSubscriptionForm.errors.emailAddress.required')),
    });
  }, [t]);

  if (isEmpty(newsletter)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h3" className={classes.text}>
          {t(`CampaignPage.Newsletter.Title`)}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {t(`CampaignPage.Newsletter.Text`)}
        </Typography>
        <Typography variant="body2" className={clsx(classes.text, classes.inputLabel)}>
          {t(`CampaignPage.Newsletter.EmailAddress.Label`)}
        </Typography>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
          {({ isSubmitting, isSubmitted, isValidating, isValid, errors }) => {
            return (
              <Form className={classes.form}>
                <FormControl
                  className={classes.formControl}
                  component="fieldset"
                  error={Boolean(errors.email)}
                  fullWidth
                  required>
                  <Field
                    id="emailAddress"
                    type="email"
                    name="emailAddress"
                    placeholder={t(`CampaignPage.Newsletter.EmailAddress.Placeholder`)}
                    title={t(`CampaignPage.Newsletter.EmailAddress.Placeholder`)}
                    component={TextField}
                    classes={{ root: classes.formField }}
                    disabled={isSubmitted}
                    required
                  />
                  <Button disabled={isSubmitting || !isValid || isValidating} type="submit" className={classes.button}>
                    <SendOutlinedIcon className={classes.buttonIcon} />
                  </Button>
                </FormControl>
                {isValid && isSuccess && (
                  <Typography variant="body2" className={classes.text}>
                    {t(`CampaignPage.Newsletter.ThankYouMessage`)}
                  </Typography>
                )}
              </Form>
            );
          }}
        </Formik>
      </Container>
    </div>
  );
}

CampaignNewsletter.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.object,
};

CampaignNewsletter.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(CampaignNewsletter);
