import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { Container, withStyles } from '@material-ui/core';
import { map } from 'lodash';
import CampaignCard from '../CampaignCard';
import CampaignSlider from '../CampaignSlider';

const styles = theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: '#373737',
  },
});

function RelatedCampaigns({ classes, className, pageData }) {
  const relatedCampaigns = get(pageData, 'relatedCampaigns');
  if (isEmpty(relatedCampaigns)) {
    return null;
  }
  return (
    <div className={clsx(classes.root, className)}>
      <Container disableGutters maxWidth="lg">
        <CampaignSlider gap={24}>
          {map(relatedCampaigns, (campaign, i) => {
            return (
              <CampaignCard
                key={`${get(campaign, 'id')}_${i}`}
                campaignData={campaign}
                gap={24}
                title={get(campaign, 'title')}
                description={get(campaign, 'shortDescription')}
                fullPath={get(campaign, 'fullPath')}
                image={get(campaign, 'orderFormProduct.relatedProduct.heroImage')}
              />
            );
          })}
        </CampaignSlider>
      </Container>
    </div>
  );
}

RelatedCampaigns.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

RelatedCampaigns.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(RelatedCampaigns);
