import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../../constants';
import { siteId } from '../../../../sites';

import FolkofolkCampaignContentLifts from './folkofolk';

function CampaignContentLifts(props) {
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkCampaignContentLifts {...props} />;
  }

  return null;
}

export default CampaignContentLifts;
