import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';

import { Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Link from '../../../navigation/Link';
import Visible from '../../../shared/Visible';
import ThumbnailImage from '../../../shared/ThumbnailImage';
import Button from '../../../shared/Button';
import { clickCampaignCard } from '../../../../utils/analyticUtils';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    wordBreak: 'break-word',
    hyphens: 'auto',
    padding: theme.spacing(1.5),
    backgroundColor: '#fff',
    flexBasis: ({ elementsPerRowMobile, gap }) => {
      if (isNaN(gap)) {
        return `calc(100%  / ${elementsPerRowMobile})`;
      }
      const totalGap = (elementsPerRowMobile - 1) * gap;
      return `calc((100% - ${totalGap}px) / ${elementsPerRowMobile})`;
    },
    maxWidth: ({ elementsPerRowMobile, gap }) => {
      if (isNaN(gap)) {
        return `calc(100%  / ${elementsPerRowMobile})`;
      }
      const totalGap = (elementsPerRowMobile - 1) * gap;
      return `calc((100% - ${totalGap}px) / ${elementsPerRowMobile})`;
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: ({ elementsPerRowTablet, gap }) => {
        if (isNaN(gap)) {
          return `calc(100%  / ${elementsPerRowTablet})`;
        }
        const totalGap = (elementsPerRowTablet - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowTablet})`;
      },
      maxWidth: ({ elementsPerRowTablet, gap }) => {
        if (isNaN(gap)) {
          return `calc(100%  / ${elementsPerRowTablet})`;
        }
        const totalGap = (elementsPerRowTablet - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowTablet})`;
      },
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: ({ elementsPerRowDesktop, gap }) => {
        if (isNaN(gap)) {
          return `calc(100%  / ${elementsPerRowDesktop})`;
        }
        const totalGap = (elementsPerRowDesktop - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowDesktop})`;
      },
      maxWidth: ({ elementsPerRowDesktop, gap }) => {
        if (isNaN(gap)) {
          return `calc(100%  / ${elementsPerRowDesktop})`;
        }
        const totalGap = (elementsPerRowDesktop - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowDesktop})`;
      },
      padding: theme.spacing(3, 1.5),
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageWrapper: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
  },
  content: {},

  title: {
    color: '#222222',
    textTransform: 'uppercase',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3, 0, 2, 0),
    },
  },
  image: {},

  shortDescription: {
    color: '#222222',
    fontSize: '0.95rem',
    marginBottom: theme.spacing(2),
  },
  linkButton: {
    display: 'flex',
    borderRadius: '9px',
    justifyContent: 'center',
    padding: '12px 14px',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

function CampaignCard({
  classes,
  className,
  campaignData,
  image,
  title,
  description,
  fullPath,
  transformation,
  width,
  height,
}) {
  const { t } = useTranslation();

  const linkButtonText = t('ContentList.ReadMoreLinkButton.AdvancedPage');

  const linkStyles = isEmpty(fullPath) ? { textDecoration: 'none' } : {};

  const handleClick = () => {
    clickCampaignCard(campaignData);
  };

  return (
    <article className={clsx(classes.root, className)}>
      <header to={fullPath} className={classes.header}>
        <Link to={fullPath} className={classes.imageWrapper} style={linkStyles} onClick={handleClick}>
          <ThumbnailImage
            className={classes.image}
            data={image}
            transformation={transformation}
            width={width}
            height={height}
          />
        </Link>

        <div className={classes.content}>
          <Link to={fullPath} onClick={handleClick}>
            <Typography variant="h5" className={classes.title} align="center">
              {title}
            </Typography>
          </Link>

          <Visible visible={isString(description) && !isEmpty(description)}>
            <Typography variant="body2" align="center" className={classes.shortDescription}>
              {description}
            </Typography>
          </Visible>
        </div>
      </header>
      <Visible hidden={isEmpty(fullPath)}>
        <Button to={fullPath} className={classes.linkButton} onClick={handleClick}>
          <InfoOutlinedIcon className={classes.icon} />
          {linkButtonText}
        </Button>
      </Visible>
    </article>
  );
}

CampaignCard.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  campaignData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  richText: PropTypes.any,
  image: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fullPath: PropTypes.string,
  transformation: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  elementsPerRowDesktop: PropTypes.number,
  elementsPerRowTablet: PropTypes.number,
  elementsPerRowMobile: PropTypes.number,
  gap: PropTypes.number,
};

CampaignCard.defaultProps = {
  classes: {},
  className: null,
  campaignData: null,
  image: null,
  richText: '',
  title: null,
  description: '',
  fullPath: null,
  transformation: 'ProductThumbnailImage-IN-SM',
  width: 'auto',
  height: '230',
  elementsPerRowDesktop: 4,
  elementsPerRowTablet: 3,
  elementsPerRowMobile: 2,
  gap: 24,
};

export default withStyles(styles)(CampaignCard);
