import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import FolkofolkContentLiftModule from './folkofolk';

function ContentLiftModule(props) {
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkContentLiftModule {...props} />;
  }

  return null;
}

export default ContentLiftModule;
